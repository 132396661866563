import React, { useEffect, useState } from 'react';
import ImgContentManagementBig from '../../assets/images/content_management_big.jpg';
import './index.scss';
import SystemNotification from './SystemNotification/index';
import Faq from './Faq/aaa';
import { useHistory } from 'react-router-dom';
import { useMount } from 'ahooks';
import axios from '../../axios-custom';
import store from '../../state/store';
const { useModel } = store;

const CM = ({ bgHandler, isUserLogin }) => {
	const [tabs, setTabs] = useState(false);
	const history = useHistory();
	// eslint-disable-next-line no-unused-vars
	const [state, dispatchers] = useModel('global');
	const { toggleLoading } = dispatchers;

	useMount(async () => {
		if (
			!localStorage.getItem('NCM_Token') ||
			localStorage.getItem('NCM_Token') === ''
		) {
			localStorage.removeItem('NCM_Token');
			localStorage.removeItem('NCM_User');
			localStorage.removeItem('NCM_AuthorizationTime');
			localStorage.removeItem('NCM_landing_popup');
			localStorage.removeItem('NCM_searchval');
			localStorage.removeItem('NCM_filterval');
			localStorage.removeItem('NCM_expandedAll');
			localStorage.removeItem('NCM_scrollY');
			localStorage.removeItem('NCM_lang_bak');
			history.replace('/');
			setTimeout(() => {
				window.location.reload();
			}, 10);
			return;
		}
		if (
			localStorage.getItem('NCM_AuthorizationTime') &&
			localStorage.getItem('NCM_AuthorizationTime') !== ''
		) {
			let newtime = Date.parse(new Date()).toString();
			newtime = newtime.substr(0, 10);
			const oldtime = localStorage.getItem('NCM_AuthorizationTime');
			if (Number.parseInt(newtime) - Number.parseInt(oldtime) > 6 * 60 * 60) {
				localStorage.removeItem('NCM_Token');
				localStorage.removeItem('NCM_User');
				localStorage.removeItem('NCM_AuthorizationTime');
				localStorage.removeItem('NCM_landing_popup');
				localStorage.removeItem('NCM_searchval');
				localStorage.removeItem('NCM_filterval');
				localStorage.removeItem('NCM_expandedAll');
				localStorage.removeItem('NCM_scrollY');
				localStorage.removeItem('NCM_lang_bak');
				history.replace('/');
				setTimeout(() => {
					window.location.reload();
				}, 10);
				return;
			}
		}
		//
		let userBak = JSON.parse(localStorage.getItem('NCM_User_bak'));
		let user = JSON.parse(localStorage.getItem('NCM_User'));
		//
		let email = '';
		if (userBak && JSON.stringify(userBak) !== '{}') {
			email = userBak.email;
		} else {
			email = user.email;
		}
		//
		toggleLoading();
		let admin = await axios.post('/feedbackServices/apiUser/getUserInfo', {
			email: email,
		});
		toggleLoading();
		if (admin && admin.data && admin.data.isAdmin === 'true') {
			//
		} else {
			history.replace('/landing');
			setTimeout(() => {
				window.location.reload();
			}, 10);
		}
	});

	useEffect(() => {
		bgHandler(true, false, ImgContentManagementBig, false);
	}, [bgHandler]);

	return (
		<section className="cm">
			<button
				className={tabs ? 'btnTab current' : 'btnTab'}
				onClick={() => setTabs(true)}
			>
				<span className="name">Notifications</span>
			</button>
			<button
				className={tabs ? 'btnTab' : 'btnTab current'}
				onClick={() => setTabs(false)}
			>
				<span className="name">FAQ</span>
			</button>
			{tabs ? <SystemNotification /> : <Faq />}
		</section>
	);
};

export default CM;
