import React, { useEffect } from 'react';
import ICONdownload from '../../assets/icons/download.svg';
import { useTranslation } from 'react-i18next';
import './index.scss';

const DetailedGuides = (props) => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
		props.bgHandler(true, false, '', false);
	}, [props]);

	const download = (type) => {
		//
		let baseURL = '';
		if (window.location.origin.includes('localhost')) {
			baseURL = 'https://qual.ncm.basf.com';
		} else if (window.location.origin.includes('dev')) {
			baseURL = 'https://dev.ncm.basf.com';
		} else if (window.location.origin.includes('qual')) {
			baseURL = 'https://qual.ncm.basf.com';
		} else {
			baseURL = 'https://ncm.basf.com';
		}
		//
		if (type === '1') {
			let link = document.createElement('a');
			link.setAttribute(
				'href',
				`${baseURL}/feedbackServices/detailedGuides/downloadDetailedGuides/Registration and Login Guide.pdf`
			);
			link.setAttribute('download', `Registration and Login Guide.pdf`);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else if (type === '2') {
			let link = document.createElement('a');
			link.setAttribute(
				'href',
				`${baseURL}/feedbackServices/detailedGuides/downloadDetailedGuides/Technical Support Form Guide.pdf`
			);
			link.setAttribute('download', `Technical Support Form Guide.pdf`);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else if (type === '3') {
			let link = document.createElement('a');
			link.setAttribute(
				'href',
				`${baseURL}/feedbackServices/detailedGuides/downloadDetailedGuides/Feedback Requests.pdf`
			);
			link.setAttribute('download', `Feedback Requests.pdf`);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else if (type === '4') {
			// window.location.href = `${baseURL}/feedbackServices/detailedGuides/downloadDetailedGuides/Feedback Requests Guide.pdf`;
			let link = document.createElement('a');
			link.setAttribute(
				'href',
				`${baseURL}/feedbackServices/detailedGuides/downloadDetailedGuides/Deputy Management Guide.pdf`
			);
			link.setAttribute('download', `Deputy Management Guide.pdf`);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else if (type === '5') {
			let link = document.createElement('a');
			link.setAttribute(
				'href',
				`${baseURL}/feedbackServices/detailedGuides/downloadDetailedGuides/Letters Guide.pdf`
			);
			link.setAttribute('download', `Letters Guide.pdf`);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<section className="faq">
			<div className="inner-text">
				<h1 className="ui-main-heading">{t('FAQ.DGintro1')}</h1>
				<h2 className="ui-sub-heading has-color">{t('FAQ.DGintro2')}</h2>
				<p className="ui-copy-text">
					{t('FAQ.DGintro3')}
					<a href="/support" className="ui-link">
						{t('FAQ.intro3-2')}
					</a>
				</p>
				<p className="ui-copy-text">{t('FAQ.note')}</p>
				<div className="faq-content-area">
					<ul className="faq-lst">
						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div className="faq-lst-item-panel" onClick={() => download('1')}>
								<h4 className="faq-lst-item-headline">
									Registration and Login Guide
								</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdownload}
									/>
								</button>
							</div>
						</li>
						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div className="faq-lst-item-panel" onClick={() => download('2')}>
								<h4 className="faq-lst-item-headline">
									Technical Support Form Guide
								</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdownload}
									/>
								</button>
							</div>
						</li>
						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div className="faq-lst-item-panel" onClick={() => download('3')}>
								<h4 className="faq-lst-item-headline">
									Feedback Requests Guide
								</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdownload}
									/>
								</button>
							</div>
						</li>
						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div className="faq-lst-item-panel" onClick={() => download('4')}>
								<h4 className="faq-lst-item-headline">
									Deputy Management Guide
								</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdownload}
									/>
								</button>
							</div>
						</li>
						<li
							className={
								props.expandedAll ? 'faq-lst-item expanded' : 'faq-lst-item'
							}
						>
							<div className="faq-lst-item-panel" onClick={() => download('5')}>
								<h4 className="faq-lst-item-headline">Letters Guide</h4>
								<button className="btnExpand">
									<img
										className="accordion-icon expand"
										alt="Expand"
										src={ICONdownload}
									/>
								</button>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default DetailedGuides;
